import { GridContainer } from 'components/lib';
import styled from 'styled-components';

export const ReadyToGetStartedContainer = styled(GridContainer)`
  margin-bottom: 120px;
  .card {
    width: 100%;
    height: 100%;
    .text {
      h3,
      a {
        max-width: 67%;
        min-height: auto;
      }
      p {
        max-width: 67%;
      }
    }
  }
  .arch {
    transform: rotate(90deg);
    margin-right: 50px;
    position: absolute;
    top: -150px;
  }
  .diagram-column {
    margin-top: 200px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
    .diagram {
      max-width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .section-title {
      margin-top: 60px;
      margin-bottom: 8px;
    }
    .diagram-column {
      order: -1;
    }
    .cards-column {
      width: 96%;
    }

    .arch {
      top: -100px;
    }

    .card {
      .text {
        h3 {
          max-width: 100%;
        }
        p {
          max-width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .carousel-title {
      margin-top: 16px;
    }
    .cards-column {
      width: 100%;

      .image-card {
        margin-top: 16px;
      }
    }
    .arch {
      top: 0;
    }
    .diagram-column {
      margin-top: 0px;
      .diagram {
        width: 95%;
      }
    }
  }
`;
