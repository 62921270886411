import React from 'react';
import Layout from 'components/commons/Layout/Layout';
import Hero from 'components/homePage/Hero/Hero';
import OurProcess from 'components/homePage/OurProcess/OurProcess';
import Testimonials from 'components/homePage/Testimonials/Testimonials';
import LearnAboutWhatToExpect from 'components/homePage/LearAboutWhatToExpect/LearnAboutWhatToExpect';
import ReadyToGetStarted from 'components/homePage/ReadyToGetStarted/ReadyToGetStarted';

const Home = () => {
  return (
    <Layout>
      <main>
        <Hero />
        <LearnAboutWhatToExpect />
        <div id="purchase">
          <OurProcess />
        </div>
        <ReadyToGetStarted />
        <Testimonials />
      </main>
    </Layout>
  );
};

export default Home;
