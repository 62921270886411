export const readyLang = {
  title: 'Ready to get started?',
  connectCard: {
    title: 'Connect with a loan officer',
    description:
      'Your loan officer will work with you and your agent to advise you on a whole range of strategies and options to help you achieve your goals.',
    buttonText: 'Connect',
  },
  purchaseCard: {
    title: 'Apply to purchase a property',
  },
  refinanceCard: {
    title: 'Apply to refinance your property',
  },
  diagram: {
    tooltip1:
      'You’ll connect with a Keller Mortgage loan officer and we’ll discuss your goals and talk about loan options.',
    tooltip2:
      'The final underwriting process will approve or deny the loan. Meanwhile your home will undergo an appraisal and inspection.',
    tooltip3:
      'All information will be reviewed by a loan processor to verify for accuracy.',
    tooltip4:
      'Keller Covered works with several leading insurance companies to secure affordable coverage that meets your needs.  Apply at <a href="https://www.kellercovered.com/" target="_blank">www.kellercovered.com</a> today!',
  },
};
