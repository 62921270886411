import GeneralCard from 'components/commons/GeneralCard/GeneralCard';
import ImageCard from 'components/commons/ImageCard/ImageCard';
import InventoryIcon from 'components/commons/svg/InventoryIcon';
import PersonIcon from 'components/commons/svg/PersonIcon';
import TopArch from 'components/commons/svg/TopArch';
import { Col, Grid, SectionTitle } from 'components/lib';
import { Origin } from 'src/common/types';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { readyLang } from 'src/data/lang/homePage/ready-to-get-started.lang';
import ReadyDiagram from './ReadyDiagram';
import { ReadyToGetStartedContainer } from './ReadyToGetStarted.styles';

const ReadyToGetStarted = () => {
  const [state, send] = useFormMachine();
  const { title, connectCard, purchaseCard, refinanceCard } = readyLang;

  const handleBeginBuy = () => {
    if (state.context.answers.buyOrRefinance !== 'buy') {
      send('CLEAR_ANSWERS');
    }
    send({
      type: `SET_ORIGIN_BUY`,
      origin: Origin.BUY_DROPDOWN,
      property: 'buyOrRefinance',
      value: 'buy',
    });
  };

  const handleBeginRefinance = () => {
    if (state.context.answers.buyOrRefinance !== 'refinance') {
      send('CLEAR_ANSWERS');
    }
    send({
      type: `SET_ORIGIN_REFINANCE`,
      origin: Origin.REFINANCE_DROPDOWN,
      property: 'buyOrRefinance',
      value: 'refinance',
    });
  };

  return (
    <ReadyToGetStartedContainer>
      <Col span="2/8" xsSpan="1/11">
        <SectionTitle className="section-title">{title} </SectionTitle>
      </Col>

      <Col span="2/8" mediumSpan="2/13" xsSpan="1/13" className="cards-column">
        <Grid columns={6}>
          <Col span="1/7">
            <GeneralCard
              title={connectCard.title}
              description={connectCard.description}
              buttonText={connectCard.buttonText}
              Icon={PersonIcon}
              href="/loan-officers"
              size="454px"
              className="card"
            />
          </Col>
          <Col span="1/4" xsSpan="1/7">
            <ImageCard
              title={purchaseCard.title}
              image="/img/home-page/ready-to-get-started/apply-purchase.png"
              icon={<InventoryIcon />}
              actionButtonText="Apply now"
              handleBeginFlow={handleBeginBuy}
            />
          </Col>
          <Col span="4/7" xsSpan="1/7">
            <ImageCard
              title={refinanceCard.title}
              image="/img/home-page/ready-to-get-started/apply-refinance.png"
              icon={<InventoryIcon />}
              actionButtonText="Apply now"
              handleBeginFlow={handleBeginRefinance}
            />
          </Col>
        </Grid>
      </Col>
      <Col
        span="8/13"
        className="diagram-column"
        mediumSpan="3/11"
        xsSpan="1/13"
      >
        <TopArch className="arch" />
        <ReadyDiagram />
      </Col>
    </ReadyToGetStartedContainer>
  );
};

export default ReadyToGetStarted;
