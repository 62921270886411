import React from 'react';

const TopArch = ({ className }: { className?: string }) => {
  return (
    <svg
      width="156"
      height="156"
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M156 -3.40949e-05C135.514 -2.96175e-05 115.228 4.03504 96.3013 11.8748C77.3745 19.7145 60.1772 31.2054 45.6913 45.6913C31.2053 60.1773 19.7145 77.3746 11.8747 96.3014C4.03501 115.228 -3.94678e-05 135.514 -3.40949e-05 156L31.2 156C31.2 139.611 34.428 123.383 40.6998 108.241C46.9716 93.0997 56.1643 79.3418 67.753 67.7531C79.3418 56.1643 93.0996 46.9716 108.241 40.6998C123.382 34.428 139.611 31.2 156 31.2L156 -3.40949e-05Z"
        fill="#9AD8B5"
      />
    </svg>
  );
};

export default TopArch;
