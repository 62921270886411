import styled from 'styled-components';

export const ImageCardWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.default};
  }

  .image-container {
    width: 100%;
    height: 116px;
    position: relative;

    .saving-icon {
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      z-index: 1;
      top: 8px;
      left: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    margin-bottom: 70px;
    a {
      cursor: pointer;
    }

    .title-button {
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }

    .action-button {
      align-self: flex-end;
      bottom: 24px;
      position: absolute;
    }
  }
`;
