import ArrowButton from 'components/commons/ArrowButton/ArrowButton';
import { H3 } from 'components/lib';
import Image from 'next/image';
import NextLink from 'next/link';
import React from 'react';
import { ImageCardWrapper } from './ImageCard.styles';

interface ImageCardProps {
  title: string;
  image: string;
  url?: string;
  icon: React.ReactNode;
  targetBlank?: boolean;
  actionButtonText?: string;
  handleBeginFlow?: () => void;
}

const ImageCard = ({
  title,
  image,
  url,
  icon,
  actionButtonText,
  targetBlank,
  handleBeginFlow,
}: ImageCardProps) => {
  return (
    <ImageCardWrapper className="image-card">
      <div className="image-container">
        <div className="saving-icon">{icon}</div>
        <Image
          src={image}
          alt={title}
          className="image"
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
      <div className="card-content">
        {handleBeginFlow && (
          <button
            onClick={() => handleBeginFlow()}
            type="button"
            className="title-button"
          >
            <H3>{title}</H3>
          </button>
        )}
        {url && (
          <NextLink
            href={url}
            target={targetBlank ? '_blank' : '_self'}
            passHref
          >
            <H3>{title}</H3>
          </NextLink>
        )}
        <div className="action-button">
          {handleBeginFlow ? (
            <ArrowButton onClick={handleBeginFlow}>
              {actionButtonText}
            </ArrowButton>
          ) : (
            <NextLink
              href={url || '/'}
              passHref
              target={targetBlank ? '_blank' : '_self'}
            >
              <ArrowButton />
            </NextLink>
          )}
        </div>
      </div>
    </ImageCardWrapper>
  );
};

export default ImageCard;
