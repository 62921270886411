import { BuyOrRefinance, GaEvent, Origin } from 'src/common/types';

export default {
  title: 'Informed, empowered, successful.',
  subTitle: 'Buying or refinancing is a big deal. Let’s do it right.',
  expertCard: {
    title: 'Expert advice',
    subTitle: 'makes all the difference',
    button: 'Find a Loan Officer',
  },
  readyToGo: {
    title: 'Ready to go?',
    subTitle: 'Let’s get started.',
    buttons: [
      {
        title: 'Get a mortgage',
        gaEvent: 'clickedGetaMortageButton' as GaEvent,
        type: BuyOrRefinance.BUY,
        origin: Origin.BUY_DROPDOWN,
      },
      {
        title: 'Refinance',
        gaEvent: 'clickedRefinanceButton' as GaEvent,
        type: BuyOrRefinance.REFINANCE,
        origin: Origin.REFINANCE_DROPDOWN,
      },
    ],
  },
};
