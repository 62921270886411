import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  .buttonSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
`;

export default Card;
