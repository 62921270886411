import {
  Col,
  Grid,
  GridContainer,
  H3,
  H4,
  PrimaryButton,
  SecondaryButton,
} from 'components/lib';
import styled from 'styled-components';

export const HeroContainer = styled(GridContainer)`
  position: relative;
  margin-top: 124px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 88px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 48px;
  }
`;

export const ResponsiveImage = styled.div<{
  span?: number | string;
  smallSpan?: number | string;
  mediumSpan?: number | string;
}>`
  position: relative;
  overflow: hidden;
  grid-column: ${({ span }) => span || 13};
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: ${({ mediumSpan }) => mediumSpan || '1/13'};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: ${({ smallSpan }) => smallSpan || '1/13'};
  }
  z-index: 2;
`;

export const MainImage = styled(ResponsiveImage)`
  border-radius: 148px 0px 0px 0px;
  height: 364px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 254px;
    border-radius: 103.173px 0px 0px 0px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 99px;
    align-self: flex-end;
    margin-top: 26px;
    border-radius: 32px 0px 0px 0px;
  }
`;

export const BorderRightBottomImage = styled(ResponsiveImage)`
  border-radius: 0px 0px 72px 0px;
  height: 180px;
  align-self: flex-end;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 126px;
    border-radius: 0px 0px 51.5865px 0px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 70px;
    grid-row: 2;
    margin-top: -31px;
    border-radius: 0px 0px 16px 0px;
  }
`;

export const BorderRightTopImage = styled(ResponsiveImage)`
  display: none;
  border-radius: 0px 32px 0px 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    height: 174px;
    margin-top: -80px;
    grid-row: 1;
  }
`;
export const BorderLeftBottomImage = styled(ResponsiveImage)`
  border-radius: 0px 0px 0px 72px;
  height: 180px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 125px;
    border-radius: 0px 0px 0px 51.5865px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-row: 3;
    height: 70px;
    margin-top: -43px;
    border-radius: 0px 0px 0px 17.3817px;
  }
`;

export const CallToActionContainer = styled(Col)`
  .container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.lg}) and (min-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    margin-top: -40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 96px;
    grid-row: 4;
  }
`;

export const TextGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ImageContainer = styled(Grid)`
  margin-top: -70px;
  ::after {
    content: '';
    position: absolute;
    background-image: url('/img/landing-page/hero/arch.svg');
    right: 141px;
    bottom: 78px;
    width: 156px;
    height: 156px;
  }
  ::before {
    position: absolute;
    content: '';
    background-image: url('/img/diagonals-big.svg');
    background-size: cover;
    width: 328px;
    height: 246px;
    bottom: 45px;
    right: 30%;
    z-index: 1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-gap: 11px;
    ::after {
      right: 90px;
      bottom: -66px;
    }
    ::before {
      width: 222px;
      height: 150px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-gap: 4px;
    margin-top: 48px;
    margin-top: 0px;
    ::after {
      display: none;
    }
    ::before {
      display: none;
    }
  }
`;

export const FloatingCardButton = styled(SecondaryButton)`
  width: 100%;
`;

export const FindLOButton = styled(PrimaryButton)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
`;

export const Subtitle = styled(H4)`
  margin-top: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 4px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const HeroTitleContainer = styled(Col)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 16px;
  }
`;

export const CardTitle = styled(H3)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -1px;
  }
`;

export const CardSubTitle = styled(H4)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`;
