import React from 'react';
import { Col, H1 } from 'components/lib';
import Image1 from 'public/img/landing-page/hero/family_organizing.png';
import Image2 from 'public/img/landing-page/hero/woman_couple_dog.png';
import Image3 from 'public/img/landing-page/hero/woman_dog.png';
import Image4 from 'public/img/landing-page/hero/dad_and_kids.png';
import FloatingCard from 'components/commons/FloatingCard/FloatingCard';
import Link from 'next/link';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';
import { GaEvent, Origin } from 'src/common/types';
import Image from 'next/image';
import heroLang from 'src/data/lang/hero.lang';
import {
  BorderLeftBottomImage,
  BorderRightBottomImage,
  BorderRightTopImage,
  CallToActionContainer,
  CardSubTitle,
  CardTitle,
  FindLOButton,
  FloatingCardButton,
  HeroContainer,
  HeroTitleContainer,
  ImageContainer,
  MainImage,
  Subtitle,
  TextGroupContainer,
} from './Hero.styles';

const Hero = () => {
  const [state, send] = useFormMachine();

  const handleBeginFlow = async (
    gaEvent: GaEvent,
    type: 'buy' | 'refinance',
    origin: Origin
  ) => {
    sendGaEvent(gaEvent, 'home');
    if (type !== state.context.answers.buyOrRefinance) {
      send('CLEAR_ANSWERS');
    }
    send({
      type: `SET_ORIGIN_${type.toUpperCase() as 'BUY' | 'REFINANCE'}`,
      origin,
      property: 'buyOrRefinance',
      value: type,
    });
  };

  return (
    <HeroContainer>
      <HeroTitleContainer span="2/8" mediumSpan="1/7" smallSpan="1/7">
        <H1>{heroLang.title}</H1>
        <Subtitle>{heroLang.subTitle}</Subtitle>
      </HeroTitleContainer>

      <CallToActionContainer span="9/12" mediumSpan="8/13" smallSpan="1/13">
        <div className="container">
          <FloatingCard>
            <TextGroupContainer>
              <CardTitle>{heroLang.expertCard.title}</CardTitle>
              <CardSubTitle>{heroLang.expertCard.subTitle}</CardSubTitle>
            </TextGroupContainer>
            <Link href="loan-officers" legacyBehavior>
              <FindLOButton>{heroLang.expertCard.button}</FindLOButton>
            </Link>
          </FloatingCard>
          <FloatingCard>
            <TextGroupContainer>
              <CardTitle>{heroLang.readyToGo.title}</CardTitle>
              <CardSubTitle>{heroLang.readyToGo.subTitle}</CardSubTitle>
            </TextGroupContainer>
            <div className="buttonSection">
              {heroLang.readyToGo.buttons.map(button => (
                <FloatingCardButton
                  key={button.title}
                  onClick={() =>
                    handleBeginFlow(button.gaEvent, button.type, button.origin)
                  }
                >
                  {button.title}
                </FloatingCardButton>
              ))}
            </div>
          </FloatingCard>
        </div>
      </CallToActionContainer>

      <Col span="2/11" mediumSpan="1/12" smallSpan="1/13">
        <ImageContainer columns={9} mediumColumns={11} smallColumns={12}>
          <MainImage span="1/7" mediumSpan="1/8" smallSpan="1/8">
            <Image
              src={Image1}
              alt="Family organizing living room"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </MainImage>
          <BorderRightTopImage smallSpan="8/13">
            <Image
              src={Image4}
              alt="Family organizing living room"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </BorderRightTopImage>

          <BorderRightBottomImage
            span="7/10"
            mediumSpan="8/12"
            smallSpan="8/13"
          >
            <Image
              alt="woman and dog"
              src={Image3}
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </BorderRightBottomImage>
          <BorderLeftBottomImage span="4/7" mediumSpan="4/8" smallSpan="3/8">
            <Image
              src={Image2}
              alt="women couple and dog"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </BorderLeftBottomImage>
        </ImageContainer>
      </Col>
    </HeroContainer>
  );
};

export default Hero;
