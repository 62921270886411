import React, { ReactNode } from 'react';
import Card from './Card-styles';

const FloatingCard = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <Card className={className}>{children}</Card>;
};

export default FloatingCard;
