import React from 'react';

const PersonIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0007 7.86732C17.5473 7.86732 18.8007 9.12065 18.8007 10.6673C18.8007 12.214 17.5473 13.4673 16.0007 13.4673C14.454 13.4673 13.2007 12.214 13.2007 10.6673C13.2007 9.12065 14.454 7.86732 16.0007 7.86732ZM16.0007 19.8673C19.9607 19.8673 24.134 21.814 24.134 22.6673V24.134H7.86732V22.6673C7.86732 21.814 12.0407 19.8673 16.0007 19.8673ZM16.0007 5.33398C13.054 5.33398 10.6673 7.72065 10.6673 10.6673C10.6673 13.614 13.054 16.0007 16.0007 16.0007C18.9473 16.0007 21.334 13.614 21.334 10.6673C21.334 7.72065 18.9473 5.33398 16.0007 5.33398ZM16.0007 17.334C12.4407 17.334 5.33398 19.1207 5.33398 22.6673V26.6673H26.6673V22.6673C26.6673 19.1207 19.5607 17.334 16.0007 17.334Z"
        fill="#1A2136"
      />
    </svg>
  );
};

export default PersonIcon;
